import React from 'react'
import { Layout, SEO } from '../components'
import './about.scss'

const About = () => {
  return (
    <Layout>
      <SEO subtitle="О проекте" />
      <div className="section-about">
        <div className="section-inner section-inner--m vertical-indent--m">
          <div className="section-about__container">
            <h1>Привет 👋</h1>
            <div className="section-about__description">
              Мы любим смотреть YouTube каналы с интервью. Навыки общения гостей таких каналов могут как восхищать, так
              и ужасать. Как-то раз нам пришла идея создать тренажер, где мы могли бы попробовать себя в роли гостей
              популярных блогеров и оценить записи своих ответов. Такой, казалось бы, простой подход показал отличные результаты в улучшении речи.
            </div>
            <div className="section-about__description">
              После этого мы решили выложить наш сервис в общий доступ. Со временем платформа стала
              дополняться вопросами на самые разнообразные темы - от "первого свидания" до "собеседования на работу".
              Будет классно, если сервис "Точка речи" будет полезен и Вам.
            </div>
            <div className="section-about__description">
              А еще мы всегда открыты для новых предложений. Если у Вас появится идея как улучшить сервис или Вы заметите
              ошибку, напишите нам на адрес <a href="mailto:hello@tochkarechi.ru">hello@tochkarechi.ru</a>. Мы будем очень благодарны!
            </div>
            <div className="section-about__description section-about__signature">
              Команда "Точки Речи"
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default About
